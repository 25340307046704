<template>
  <div class="">

    <!-- Content Header (Page header) -->
    <div class="">
      <custom-alert v-if="displayAlert==true" :message="alertMessage"
                    v-on:showedAlert="resetAlert"></custom-alert>
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Main row -->
          <div class="row">
            <section
              class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3"
            >
              <div class="card">
                <div class="card-body table-responsive">
                  <div class="row bor-bot">
                    <div class="col-md-8 pl-0">
                      <h1 class="m-0 text-dark pt-2 pb-3">Manage Positions</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                            <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                            </ol>
                    </div>
                    </div>                   
                  </div>
                  <div class="">
                        <div class="row">
                          
                        <div class="col-lg-8 mr-0 pr-0">
                          <div class="mt-3">
                            <b-form inline>
                            <label class="mr-sm-2 font-weight-normal">Show</label>
                            <b-form-select
                              id="inline-form-custom-select-pref"
                              class="mb-2 mr-sm-2 mb-sm-0"
                              v-model="perPage"
                              :options="viewEntryOptions"
                              size="sm"
                            ></b-form-select
                            >entries
                          </b-form>

                          </div>
                        
                        </div>

                        <div class="col-lg-4  text-right pr-0">
                          <div class=" card-text d-flex align-items-center float-right"> 
                          <div class="mt-3"> 
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filter"
                              type="search"
                              placeholder="Search"
                            ></b-form-input>
                          </b-input-group>
                          </div>
                          <div class="mt-3 ml-3">
                              <b-button
                              variant="outline-primary"
                              @click="addRow($event.target)"
                              >Add Position</b-button
                            >
                          </div>
                          </div>
                        </div>

                        </div>
                  </div>
                


                  <b-table
                    ref="merchandiseTable"
                    id="merchandise-table"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="items"
                    :fields="fields"
                    :busy="!loaded"
                    class="mt-3"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    :sort-by.sync="detailsSortBy"
                    :sort-desc.sync="detailsSortDesc"
                    hover
                    show-empty
                  >
                    <template #cell(actions)="row">
                      <div class="text-left py-0 align-middle">
                        <div class="btn-group btn-group-sm">
                          <button
                            class="btn btn-primary"
                            @click="viewRow(row.item, row.index, $event.target)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                          <button
                            class="btn btn-primary ml-1"
                            @click="editRow(row.item, $event.target)"
                          >
                            <i class="fas fa-pencil-alt"></i>
                          </button>
                          <button
                            class="btn btn-danger ml-1"
                            @click="deleteRow(row.item.id)"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template #empty="scope">
                      <h4>{{ scope.emptyText }}</h4>
                    </template>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                  </b-table>
                  <br />
                  <b-pagination
                    v-if="loaded"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    prev-text="Previous"
                    next-text="Next"
                    aria-controls="merchandise-table"
                    class="float-right custom-color"
                    hide-goto-end-buttons
                  ></b-pagination>
                  <!-- Info modal -->
                  <b-modal
                    :id="infoModal.id"
                    :title="infoModal.title"
                    size="lg"
                    ok-only
                    @hide="resetInfoModal"
                  >
                    <dl class="row">
                      <dt class="col-sm-2">Position</dt>
                      <dd class="col-sm-10">{{ infoModal.content.position }}</dd>

                      <dt class="col-sm-2">Order</dt>
                      <dd class="col-sm-10">{{ infoModal.content.order }}</dd>
                    </dl>
                  </b-modal>
                  <!-- Edit modal -->
                  <b-modal
                    id="edit-modal"
                    :title="editModal.title"
                    size="lg"
                    ref="modal"
                    @ok="handleOk"
                    @hide="resetEditModal"
                  >
                    <form ref="form" @submit.stop.prevent="handleSubmit">
                    <div class="row">
                      <div class="col-md-6">
                          <div>
                            <label class="pt-4 form-label" for="feedback-position"
                              >Position</label
                            >
                            <b-form-input
                              @keydown="errors.clear('position')"
                              v-model="editModal.content.position"
                              :class="errors.has('position') ? 'is-invalid' : ''"
                              id="feedback-position"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              :state="!errors.has('position')"
                            >
                              {{ errors.get("position") }}</b-form-invalid-feedback
                            >
                          </div>
                      </div>
                      <div class="col-md-6">
                        <div>
                          <label class="pt-4 form-label" for="feedback-order"
                          >Order</label
                          >
                          <b-form-input
                              @keydown="errors.clear('order')"
                              v-model="editModal.content.order"
                              :class="errors.has('order') ? 'is-invalid' : ''"
                              id="feedback-order"
                          ></b-form-input>
                          <b-form-invalid-feedback
                              :state="!errors.has('order')"
                          >
                            {{ errors.get("order") }}</b-form-invalid-feedback
                          >
                        </div>
                      </div>
                    </div>
                    </form>
                  </b-modal>
                </div>
              </div>
            </section>
          </div>
          <!-- /.row (main row) -->
        </div>
        <!-- /.container-fluid -->
      </section>
    </div>
    <!-- /.content -->

  </div>
</template>

<script>

import axios from "axios";
import Errors from "../../Errors.js";
import moment from "moment";
import CustomAlert from "../CustomAlert";
/*import DatePick from "vue-date-pick";*/

let cachedData = {};

export default {
  name: "committeePosition",
  components: { CustomAlert },

  data() {
    return {
      loaded: false,
      alertMessage:'',
      displayAlert:'',
      perPage: 10,
      currentPage: 1,
      detailsSortBy:'position',
      detailsSortDesc:false,
      errors: new Errors(),
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "position",
          sortable: true,
        },
        {
          key: "order",
          sortable: true,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
      items: [],
      viewEntryOptions: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 20, text: "20" },
      ],
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      editModal: {
        editing: true,
        id: "edit-modal",
        title: "",
        content: {},
      },
    };
  },

  filters: {
    productStatus(value) {
      return value ? "Active" : "Inactive";
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
  },

  methods: {
     formatlongDate(datevalue)
        {
            var date = moment(datevalue);
            if(date.isValid()){
                return date.format('MM/DD/YYYY');
            } else {
                return 'N/A';
            }
        },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addRow(button) {
      (this.editModal.editing = false),
        (this.editModal.title = "Add New Committee Position");
      let id = localStorage.getItem("id");
      this.editModal.content = {
        position: "",
        order: "",
      };
      this.$root.$emit("bv::show::modal", "edit-modal", button);
    },
    viewRow(item, index, button) {
      this.infoModal.title = "View " + item.position;
      this.infoModal.content = item;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    editRow(item, button) {
      (this.editModal.editing = true),
        (this.editModal.title = "Update " + item.position);
      this.editModal.content = item;
      console.log(item);
      this.$root.$emit("bv::show::modal", "edit-modal", button);
    },
    deleteRow(id) {
      this.displayAlert = false;
      this.$bvModal.msgBoxConfirm('Are you sure to delete this item?', {
        title: 'Please Confirm',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(async value => {
            if(String(value)=="true")
            {
              axios
                  .delete(process.env.VUE_APP_URL + "api/committee_positions/delete/" + id)
                  .then((response) => {
                    this.alertMessage = "Committee Position deleted!";
                    this.displayAlert = true;
                    this.resetEditModal();
                  })
                  .catch((error) => {
                    (error) => (this.errors = error.response.data);
                  });
              return false;
            }
          })
          .catch(err => {
            console.log(err);
          })
    },

    //Modals
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    resetEditModal() {
      this.errors.clear();
      this.loaded = false;
      axios
        .get(process.env.VUE_APP_URL + "api/committee_positions")
        .then((response) => {
          cachedData = response.data.committee_positions;
          this.items = cachedData;
          this.loaded = true;
        })
        .catch((error) => (this.errors = error.response.data));
      this.loaded = true;
    },

    // Submitting
    onFail(errors) {
      this.errors.record(errors);
    },

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      let url = process.env.VUE_APP_URL + "api/committee_positions";
      let requestType = "post";

      if (this.editModal.editing) {
        url = process.env.VUE_APP_URL + "api/committee_positions/update/" + this.editModal.content.id;
        requestType = "patch";
      }

      this.handleSubmit(requestType, url)
        .then(() => {
          this.$nextTick(() => {
            var message = this.editModal.editing ? "updated" : "created";
            this.alertMessage = "Committee Position has been " + message + ".";
            this.displayAlert = true;
            this.$bvModal.hide("edit-modal");
          });
        })
        .catch(() => false);
    },

    handleSubmit(requestType, url) {
      return new Promise((resolve, reject) => {
        axios[requestType](url, this.editModal.content)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            this.onFail(error.response.data.errors);
            reject(error.response.data);
          });
      });
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
  },

  created() {
    if (Object.keys(cachedData).length === 0) {
      axios
        .get(process.env.VUE_APP_URL + "api/committee_positions")
        .then((response) => {
          cachedData = response.data.committee_positions;
          this.items = cachedData;
          this.loaded = true;
        })
        .catch((error) => (this.errors = error.response.data));
      return false;
    }
    this.items = cachedData;
    this.loaded = true;
  },

  computed: {
    rows() {
      return this.items.length;
    },
  },
};
</script>
